.trip-history-container {
    margin: 20px 20px;
    padding: 20px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    display: flex;
    gap: 20px;
    flex: 1;
}

.controls {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.controls button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
}

.controls button:hover {
    background: #0056b3;
}

.slider-1 {
    width: 80%;
    margin-top: 10px;
}

.trip-info {
    margin-top: 15px;
    font-size: 16px;
    color: #333;
}

.trip-info p {
    margin: 5px 0;
}

.delivery-logs-container {
    overflow-y: scroll;
    background-color: #f8f9fa;
    padding: 15px;
    flex: 1;
}

.delivery-logs-container h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
}

.delivery-log {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.delivery-log p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
    text-align: left;
}

.delivery-log .log-type {
    font-weight: bold;
    color: #007bff;
}

.delivery-log .log-time {
    font-size: 12px;
    color: #777;
}

.map-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.arrow i {
    font-size: 16px;
    color: #007bff;
}
