#orderDetailsRootContainer {
    display: flex;
    flex-direction: column;
}

#topBtnsDiv {
    display: flex;
    flex: 1;
    margin: 10px 20px;
    padding: 0px;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

#topBtnsDiv button {
    border: 0;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
    background-color: white;
    border-radius: 12px;
    padding: 12px 15px 12px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

#topBtnsDiv div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

#orderDetailsContainer {
    display: flex;
    flex: 1;
    margin: 5px 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
    flex-direction: row;
}

#orderDetailsContainer #sellerDetails {
    display: flex;
    padding: 20px;
    flex: 0.5;
    flex-direction: column;
}

#orderDetailsContainer #orderDetails {
    flex-direction: column;
    display: flex;
    flex: 0.5;
    padding: 20px;
}

#orderDetailsRootContainer h2 {
    margin: 5px 0px;
    text-transform: capitalize;
    font-weight: 600;
}

#orderDetailsContainer .line {
    height: 1px;
    background-color: rgb(216, 216, 216);
    display: flex;
    margin: 5px 0% 5px 0;
}

#orderDetailsRootContainer h4 {
    margin: 5px 0px;
    text-transform: capitalize;
    font-weight: 700;
}

#orderDetailsRootContainer span {
    font-weight: 400;
    margin: 5px 5px 5px 0;
    text-transform: capitalize;
    color: rgb(70, 70, 70);
}

#dateDiv {
    display: flex;
    flex: 1;
    margin: 10px 20px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    border-radius: 12px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
    flex-wrap: wrap;
}

#orderDetailsRootContainer #productList {
    background-color: white;
    margin: 10px 20px;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
    overflow-x: auto;
}

#productList table {
    width: 100%;
    border-collapse: collapse;
}

#productList th, #productList td {
    padding: 8px;
    text-align: left;
}

.updateOrderTable {
    border-collapse: collapse;
    border: 1px solid black;
    width: 100%;
}

.updateOrderTable tr,
.updateOrderTable th,
.updateOrderTable td {
    padding: 10px 20px;
    border: 1px solid rgb(0, 0, 0);
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
    #orderDetailsContainer {
        flex-direction: column;
        height: auto;
    }
    
    #orderDetailsContainer #sellerDetails,
    #orderDetailsContainer #orderDetails {
        flex: 1;
        width: 100%;
    }
    
    #dateDiv {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding-right: 20px;
    }
    
    #topBtnsDiv {
        flex-direction: column;
        align-items: flex-start;
    }
    
    #topBtnsDiv div {
        margin-top: 10px;
    }
    
    #productList {
        overflow-x: auto;
    }
    
    #productList table {
        min-width: 650px;
    }
}

@media screen and (max-width: 480px) {
    #topBtnsDiv, #orderDetailsContainer, #dateDiv, #productList {
        margin: 5px 10px;
    }
    
    #topBtnsDiv button {
        width: 100%;
    }
    
    #orderDetailsContainer #sellerDetails,
    #orderDetailsContainer #orderDetails {
        padding: 10px;
    }
    
    .updateOrderTable tr,
    .updateOrderTable th,
    .updateOrderTable td {
        padding: 2px;
    }
}