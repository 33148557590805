

.chart-container {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.chart-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.button {
    padding: 4px 12px;
    border-radius: 4px;
    border: none;
    background-color: #e2e2e2;
    cursor: pointer;
    font-size: 14px;
}

.button.active {
    background-color: #2196F3;
    color: white;
}

.chart-wrapper {
    margin-top: 1%;
    width: 100%;
    height: 400px;
    padding: auto;
}

.loading-container {
    text-align: center;
    padding: 16px;
}

.error-container {
    text-align: center;
    padding: 16px;
    color: #f44336;
}
.dropdown {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
}

@media (max-width: 600px) {
    .dropdown {
        background-color: #e2e2e2;
        color: #333;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .chart-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .chart-title {
        font-size: 18px;
    }

    .button-group {
        flex-direction: column;
        width: 100%;
    }

    .button {
        width: 100%;
        text-align: center;
    }

    .chart-wrapper {
        height: 300px; 
        
    }

    .timeframe-selector {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    .timeframe-selector .button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .chart-title {
        font-size: 16px;
    }

    .button {
        font-size: 12px;
        padding: 4px 8px;
    }

    .chart-wrapper {
        height: 250px; 
    }
}