:root {
    --primary-bg: #f8f8f8;
    --white: #ffffff;
    --text-dark: #333333;
    --text-light: #6b7280;
    --border-color: #e5e7eb;
    --status-delivered: #d1fae5;
    --status-delivered-text: #065f46;
    --status-confirmed: #dbedff;
    --status-confirmed-text: #1e40af;
    --status-cancelled: #fde8e8;
    --status-cancelled-text: #b91c1c;
    --status-processed: #fef3c7;
    --status-processed-text: #92400e;
    --status-dispatched: #ede9fe;
    --status-dispatched-text: #5b21b6;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {    
    
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.6;
    background-color: var(--primary-bg);
    color: var(--text-dark);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
}

.card {
    background: var(--white);
    padding: 25px;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--border-color);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-color);
}

.user-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-info .icon {
    background: #dceefb;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-info h2 {
    font-size: 1.25rem;
    color: var(--text-dark);
    margin-bottom: 5px;
}

.user-info p {
    color: var(--text-light);
    font-size: 0.875rem;
}

.status-section {
    display: flex;
    gap: 30px;
}

.status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: capitalize;
}

.status.delivered { background: var(--status-delivered); color: var(--status-delivered-text); }
.status.confirmed { background: var(--status-confirmed); color: var(--status-confirmed-text); }
.status.cancelled { background: var(--status-cancelled); color: var(--status-cancelled-text); }
.status.processed { background: var(--status-processed); color: var(--status-processed-text); }
.status.dispatched { background: var(--status-dispatched); color: var(--status-dispatched-text); }
.status.online { background: var(--status-delivered); color: var(--status-delivered-text); }
.status.offline { background: var(--status-cancelled); color: var(--status-cancelled-text); }

.stats {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.stat-box {
    flex: 1;
    padding: 15px;
    background: #f9fafb;
    border-radius: 10px;
    text-align: center;
    border: 1px solid var(--border-color);
}

.stat-box p {
    margin-top: 8px;
    color: var(--text-light);
}

.stat-box .bold {
    font-weight: 600;
    color: var(--text-dark);
}

.icon-blue { color: #2563eb; }
.icon-green { color: #16a34a; }
.icon-purple { color: #7c3aed; }
.icon-red { color: #dc2626; }

.table-container {
    overflow-x: auto;
    margin-top: 15px;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
}

th {
    background-color: #f9fafb;
    font-weight: 600;
    color: var(--text-light);
    text-transform: uppercase;
    font-size: 0.75rem;
}

.product-images {
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-images img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 8px;
}

.more-products {
    background-color: #f3f4f6;
    color: var(--text-light);
    padding: 2px 8px;
    border-radius: 20px;
    font-size: 0.75rem;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.pagination button {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--text-light);
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination button.active {
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
}

.pagination button:hover {
    background-color: #f3f4f6;
    color: var(--text-dark);
}

.order-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.address {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-light);
}

@media screen and (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .status-section {
        flex-direction: column;
        gap: 10px;
    }

    .stats {
        flex-direction: column;
    }

    .order-details {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    table {
        font-size: 0.875rem;
    }

    th, td {
        padding: 8px;
    }
}

@media screen and (max-width: 480px) {
    .user-info {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .product-images img {
        width: 30px;
        height: 30px;
    }
}