#deliveryOrdersList {
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 20px;

    h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .filters {
        margin-bottom: 15px;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    input[type="date"],
    button {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        background-color: #007bff;
        color: white;
        cursor: pointer;
    }

    button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th,
    td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }

    th {
        background-color: #f2f2f2;
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .pagination button {
        margin: 0 5px;
        padding: 5px 10px;
    }
}
