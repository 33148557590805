#FilterOrdersDiv {
    display: flex;
    flex: 1;
    margin: 20px 20px;
    background-color: white;
    flex-direction: row;
    border-radius: 12px;
    flex-wrap: wrap;
}

button {
    cursor: pointer;
}

#dateNav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 0;
    margin: 10px 20px;
    flex-wrap: wrap;
}

#filterNav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 10px 20px;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
}

.searchOrder {
    max-width: 400px;
    display: flex;
    flex: 1;
    border-radius: 12px;
    border-color: rgb(210, 210, 210);
    padding: 16px 10px;
}

.SearchButton {
    background-color: #ffcb2d;
    padding: 12px 12px;
    border: 1px solid #daa503;
    border-radius: 12px;
}

.ExportButton {
    background-color: #0000001a;
    padding: 12px 12px;
    border: 1px solid #00000033;
    border-radius: 12px;
}

#OrdersListDiv {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    margin: 20px 20px;
    padding: 10px 30px;
    border-radius: 12px;
}

.orderNav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.orderNav h5 {
    font-size: 20px;
    font-weight: 500;
}

.orderNavButtons {
    border-radius: 24px;
    background-color: grey;
    overflow: hidden;
    border: 1px solid rgb(218, 218, 218);
}

.orderNavButtons button {
    border: 1px solid rgba(218, 218, 218, 0) !important;
    padding: 10px;
}

.orderNavButtons button.active {
    background-color: rgba(98, 98, 98, 0.158);
    color: white;
}
.orderNavButtons button.active1 {
    background-color: #0a3b64;
    color: white;
}
.orderNavButtons button.normal1 {
    background-color: #eee;
    color: #0a3b64;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
    cursor: default;
}

.headerRow {
    background-color: rgba(212, 212, 212, 0.618);
}

.headerRow th {
    padding: 10px 0;
    font-weight: 500;
}

tbody tr {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
}

tbody td {
    padding: 10px 15px;
}

.greytext {
    color: grey;
    font-size: 12px;
    margin: 0;
    text-align: left;
}

.blacktext {
    color: black;
    font-size: 16px;
    margin: 0;
    text-align: left;
    text-transform: capitalize;
    font-weight: 600;
}

.boldtext {
    color: black;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    text-align: left;
    text-transform: capitalize;
}

.spaceLine {
    background-color: rgb(216, 216, 216);
    height: 1px;
    margin: 10px 30% 10px 5px;
}

.modifiedBtn button {
    border-radius: 16px;
    padding: 8px 16px;
    border: 0;
    margin: 0px 5px;
}

.headingText {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

td button {
    border-radius: 16px;
    padding: 8px 16px;
    border: 0;
    margin: 0px 5px;
}

.greenBtn {
    background-color: #0d9e67;
    color: white;
    text-wrap: nowrap;
}

.redBtn {
    background-color: #e21b1b1a;
    text-wrap: nowrap;
    color: #e21b1b;
}

.greyBtn {
    background-color: #0000000d;
    text-wrap: nowrap;
    color: #00000080;
}

.cellContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.halfcellContainer {
    flex: 0.5;
}

#dateNav select {
    height: 40px;
    border: 0;
    border-radius: 12px;
    margin-right: 20%;
    margin-left: 20px;
    background-color: #9898981a;
    padding: 0 5px;
}

.otpInputBox {
    background-color: transparent;
    border-radius: 24px;
    width: 7rem;
    border: 1px solid rgb(196, 196, 196);
    text-align: center;
    padding: 10px 0px;
}

.cardDiv {
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px rgb(100, 98, 98);
    padding: 0;
    border-radius: 12px;
    cursor: default;
    transition: transform 0.2s;
}
.cardDiv2 {
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px rgb(238 238 238 / 54%);
    padding: 0;
    border-radius: 12px;
    background: #0a3b6422;
    cursor: default;
    transition: transform 0.2s;
}
.cardDiv2:hover {
    transform: scale(1.005);
    outline: 1px dashed #0a3b64;
    box-shadow: 4px 4px 20px #aaa;
}

.cardDiv .headerDiv {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 50px;
    border-radius: 10px 10px 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.cardDiv .bodyDiv {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    /* Adding "px" unit */
    margin-bottom: 10px;
    /* Using hyphen instead of camelCase */
    padding: 20px;
}

.cardDiv .timeLineDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: row;
    padding: 20px;
}

.cardDiv2 .bodyDiv2 {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 10px;
    padding: 20px;
}

.cardDiv2 .timeLineDiv2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: row;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    #OrdersListDiv {
        margin: 10px 5px;
        padding: 10px 10px;
    }
    #filterNav {
        flex-direction: column;
        gap: 10px;
        margin: 10px;
        width: 100%;
    }

    .searchOrder {
        max-width: 100%;
    }

    #filterNav button {
        max-width: 100% !important;
    }

    #dateNav {
        flex-direction: column;
        margin: 10px;
        align-items: center;
    }

    #dateNav > div {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .orderNavButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .orderNavButtons button {
        margin: 5px;
        flex-grow: 1;
    }

    .orderNav {
        flex-direction: column;
        margin-bottom: 1px;
    }

    table {
        display: block;
        overflow-x: auto;
    }
    .cardDiv .bodyDiv,
    .cardDiv2 .bodyDiv2 {
        flex-direction: column;
    }

    .cardDiv .timeLineDiv,
    .cardDiv2 .timeLineDiv2 {
        display: flex;
        flex-direction: column;
    }

    .timeLineDiv {
        flex-direction: column !important;
        align-items: flex-start !important;
        padding: 10px !important;
    }

    .timeline-item {
        width: 100% !important;
        flex-direction: column !important;
    }

    /* Adjust the arrow to point downward */
    .timeline-item svg {
        transform: rotate(0deg) !important;
    }

    /* Center the timeline content */
    .timeline-item > div {
        width: 100% !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .timeline-item {
        display: flex;
        flex-direction: column !important;
        width: 100% !important;
        align-items: center !important;
    }

    .timeline-arrow-container {
        transform: rotate(180deg) !important;
        height: 10px !important;
    }

    .timeline-content {
        width: 80% !important;
    }

    .timeline-box {
        width: 100% !important;
        max-width: 250px !important;
    }

    .timeline-arrow-svg {
        transform: rotate(0deg) !important;
    }

    .timeline-difference {
        transform: rotate(180deg) !important;
    }
    #FilterOrdersDiv {
        flex-direction: column;
        margin: 10px;
    }

    #dateNav,
    #filterNav {
        flex-direction: column;
        gap: 10px;
        margin: 10px;
    }

    .searchOrder {
        max-width: 100%;
    }

    .cardDiv .headerDiv {
        display: none;
    }

    .cardDiv .bodyDiv {
        flex-direction: column;
        padding: 15px 10px;
    }

    /* Adjust all cells to stack vertically */
    .bodyDiv [class^="CELL"] {
        flex: 1 !important;
        max-width: 100% !important;
        width: 100% !important;
        /* margin-bottom: 5px; */
        /* padding: 1px 0; */
    }

    .bodyDiv .CELL:before {
        content: attr(data-label);
        font-weight: bold;
        display: inline-block;
        width: 40%;
    }

    .timeLineDiv {
        flex-direction: column;
        justify-content: center;
        padding: 5px;
    }

    .timeLineDiv > div {
        flex: 1 !important;
        margin-bottom: 5px;
    }
    .timeline-item-Cont {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

    /* Button adjustments */
    .modifiedBtn {
        width: 100% !important;
    }

    .modifiedBtn button {
        width: 100%;
        margin: 5px 0;
    }

    /* Action buttons stack vertically */
    button.greenBtn,
    button.redBtn,
    button.greyBtn {
        display: block;
        width: 100%;
        /* margin: 5px 0; */
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .cardDiv .bodyDiv {
        flex-wrap: wrap;
    }

    .bodyDiv .CELL {
        min-width: 150px;
    }

    .modifiedBtn button {
        padding: 8px 10px;
        font-size: 14px;
    }
}
@media screen and (max-width: 480px) {
    #FilterOrdersDiv {
        margin: 5px;
    }

    .searchOrder {
        padding: 10px 5px;
    }
    .timeline-item-Cont {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .orderNavButtons button {
        width: auto !important;
        white-space: nowrap;
        padding: 8px 5px;
        font-size: 12px;
    }

    .ExportButton {
        width: 100%;
        margin-top: 10px;
    }

    .date-picker-input {
        width: 100%;
    }
    .orders-container {
        margin: 20px;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        max-width: 900px;
        margin: 0 auto;
    }

    .orders-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
    }

    .table-wrapper {
        overflow-x: auto;
    }

    .orders-table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #ddd;
    }

    .orders-table th,
    .orders-table td {
        padding: 12px;
        text-align: left;
        border: 1px solid #ddd;
    }

    .orders-table th {
        background-color: #f4f4f4;
    }

    .orders-table tr:hover {
        background-color: #f9f9f9;
    }

    .view-order-btn {
        background-color: #007bff;
        color: white;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
    }

    .view-order-btn:hover {
        background-color: #0056b3;
    }

    .loading,
    .error {
        text-align: center;
        font-size: 16px;
        margin-top: 20px;
    }

    .no-orders {
        text-align: center;
        padding: 15px;
        font-size: 16px;
        color: #777;
    }
}
