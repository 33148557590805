* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.chart-container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 90%;
    margin: 20px auto;
    overflow-y: hidden;
    overflow: hidden;
}

.chart-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    overflow: hidden;
}


