#changePassDiv {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
}
#changePassDiv input {
    padding: 20px 20px;
    display: flex;
    flex: 1;
    background-color: #e1e1e16c;
    border: 1px solid #c5c5c5;
}
#changePassDiv .line {
    margin: 20px 10px;
    border-bottom: 0px solid #ccc;
    width: calc(100% - 20px);
    background-color: rgb(190, 190, 190);
    height: 1px;
}
#userList {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
}
.modal {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    padding: 20px;
    /* background-color: rgba(255, 0, 0, 0.251); */
}
.modalContainer {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: flex;
    z-index: 999;
}
.AddUserBtn {
    padding: 10px 20px;
    border-radius: 16px;
    border: 1px solid gainsboro;
}


/* Existing CSS remains the same */

/* Responsive Styles */
.iam-container {
    max-width: 100%;
    padding: 10px;
}

.user-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
    gap: 30px;
    flex-wrap: wrap;
}

.user-input {
    padding: 10px;
    flex: 1;
    min-width: 200px;
}

.header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
}

.action-buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}

.cancel-btn {
    background-color: #E21B1B1A;
    border: 1px solid #E21B1B;
    color: #E21B1B;
    padding: 10px 20px;
    border-radius: 16px;
    cursor: pointer;
}

.save-btn {
    background-color: #0D9E671A;
    border: 1px solid #0D9E67;
    color: #0D9E67;
    padding: 10px 20px;
    border-radius: 16px;
    cursor: pointer;
}

.permissions-table-container {
    overflow-x: auto;
}

.permissions-table {
    width: 100%;
    min-width: 400px;
}

.change-pass-container {
    margin-bottom: 20px;
}

.password-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px 20px 30px 20px;
    gap: 30px;
    flex-wrap: wrap;
}

.password-input {
    padding: 10px;
    flex: 1;
    min-width: 200px;
    margin-bottom: 10px;
}

.user-list-container {
    margin-top: 20px;
}

.table-responsive {
    overflow-x: auto;
}

.users-table {
    width: 100%;
    min-width: 500px;
    border-collapse: collapse;
}

.users-table th, .users-table td {
    padding: 10px;
    text-align: left;
}

/* Hide certain columns on mobile */
@media screen and (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
    
    .user-form {
        flex-direction: column;
        gap: 15px;
    }
    
    .action-buttons {
        margin-top: 10px;
    }
    
    .password-inputs {
        flex-direction: column;
        gap: 15px;
        margin: 15px 10px;
    }
       
    .AddUserBtn {
        margin-top: 10px;
    }
}